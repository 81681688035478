<template>
  <div v-if="isVisible" class="form-container bg-gray-50">
    <div class="hidden">
      <div
        class="p-10 bg-white text-center"
        style="width: 105mm; height: 140mm"
        id="card-print"
      >
        <!-- school logo -->
        <div class="mb-6 grid grid-cols-4">
          <img
            class="w-12 sm:inline-block mr-2"
            src="@/assets/logo.png"
            alt=""
          />
          <p class="col-span-3 text-left">
            <span class="block">SDIT AL MADINAH PONTIANAK</span>
            <span class="text-gray-500 text-sm">KARTU SELEKSI PESERTA</span>
          </p>
        </div>
        <!-- photo -->
        <div
          class="border border-gray-400 border-dashed mx-auto tracking-wider flex items-center"
          style="width: 30mm; height: 40mm"
        >
          <span class="text-center text-gray-400">FOTO 3X4 BERWARNA</span>
        </div>

        <!-- Name -->
        <span class="subtle-label mt-6">Nama</span>
        <p class="mb-6 text-xl tracking-wide">{{ selected_student.name }}</p>

        <!-- Schedule & location -->
        <div class="grid grid-cols-2">
          <!-- schedule -->
          <div>
            <span class="subtle-label">Waktu Seleksi</span>
            {{ cardDate }} <br />
            {{ selected_student.period_schedule_started_at | time_only }}
            -
            {{ selected_student.period_schedule_ended_at | time_only }}
          </div>
          <!-- location -->
          <div>
            <span class="subtle-label">ruangan seleksi</span>
            {{ selected_student.schedule.location }}
          </div>
        </div>

        <p class="tracking-wider mt-4 text-sm text-left">
          Mohon datang paling lambat 5 menit sebelum waktu seleksi.
        </p>
      </div>
    </div>

    <!--  -->
    <div class="text-center my-6">
      <slot name="goto-prev-form-trigger"></slot>
    </div>
    <div class="p-4 container max-w-screen-sm mx-auto bg-white shadow mb-6">
      <!-- Top Header -->
      <div class="form__header">
        <div class="container max-w-screen-lg mx-auto mt-2">
          <div class="grid grid-cols-2 px-2">
            <div>
              <button
                class="px-4 py-2 uppercase tracking-wider"
                @click="triggerHideForm()"
              >
                <fa-icon icon="arrow-left"></fa-icon>
                <span class="ml-2">Keluar</span>
              </button>
            </div>

            <div class="text-center">
              <!-- trigger print -->
              <button
                class="ml-2 px-4 py-2 text-green-500 uppercase tracking-wider"
                @click="triggerPrint()"
              >
                <fa-icon icon="download"></fa-icon>
                <span class="ml-2">Kartu Seleksi</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Form -->
      <slot></slot>
      <button
        class="button --primary uppercase tracking-wider text-white mt-6"
        @click="triggerUpdate()"
      >
        Simpan
      </button>
    </div>

    <!-- next form trigger -->
    <div class="text-center my-6">
      <slot name="goto-next-form-trigger"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cardDate() {
      let startTest = new Date(this.selected_student.schedule.started_at);
      return `${startTest.getDate()}/${
        startTest.getMonth() + 1
      }/${startTest.getFullYear()}`;
    },
    ...mapGetters("student", ["selected_student"]),
  },
  methods: {
    triggerUpdate() {
      this.$emit("update-triggered");
    },
    triggerHideForm() {
      this.HIDE_FORM();
      // this.$emit("hide");
    },
    triggerPrint() {
      if (!this.isDownloadAllowed()) {
        this.$toasted.info(
          "Mohon pastikan semua data diri, alamat, dan orang tua calon siswa yang wajib diisi telah dilengkapi"
        );
        return false;
      }
      const element = document.getElementById("card-print");
      let opt = {
        margin: 0,
        filename: `kartu-tes-peserta-didik.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
        jsPDF: { unit: "mm", format: "a6" },
      };
      html2pdf().from(element).set(opt).toPdf().save();
    },
    isDownloadAllowed() {
      let student = this.selected_student;
      let studentCheck = [
        "nik",
        "birth_place",
        "birth_date",
        "sibling_position",
        "sibling_total",
        "height",
        "weight",
      ];

      try {
        studentCheck.forEach((element) => {
          if (student[element] === null) {
            throw "Tardapat data yang belum diisi pada identitas diri";
          }
        });
        Object.keys(this.selected_student.address).forEach((el) => {
          if (this.selected_student.address[el] === null) {
            throw "Tardapat data yang belum diisi mengenai ALAMAT";
          }
        });
        this.selected_student.parents.forEach((parent) => {
          Object.keys(parent).forEach((el) => {
            if (parent[el] === null && parent.is_guardian === 0) {
              let subject = parent.gender === 1 ? "Ayah" : "Ibu";
              throw (
                "Terdapat data yang belum diisi mengenai informasi " + subject
              );
            }
          });
        });
        return true;
      } catch (error) {
        this.$toasted.error("Tidak dapat mengunduh Kartu Ujian!");
        this.$toasted.error(error);
        return false;
      }
    },

    ...mapMutations("student", ["HIDE_FORM", "SHOW_FORM"]),
  },

  name: "StudentForm",
};
</script>


<style lang="scss" scoped>
.form-container {
  @apply fixed top-0 left-0 p-8 pt-20 z-40 w-full overflow-y-auto max-h-screen;
}

.form__header {
  @apply mb-10 gap-2 shadow;
  @apply fixed w-full top-0 left-0 pb-4 pt-1 z-40 bg-white;
}

.trigger-hide {
  @apply fixed left-3 -mt-2 top-0 w-12 h-12 z-50;
  @apply shadow-lg text-white text-xl;
  @apply rounded-b-full bg-gray-800 bg-opacity-80;
}

.form-label {
  @apply uppercase tracking-wider mb-1 mt-4 block font-bold text-green-800;
}

input[type="text"] {
  @apply w-full;
}
</style>
